import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Impact Dialog', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Impact training for researchers', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'aboutimg.png',
  paragraphOne:
    'I have more than 15 years of interdisciplinary expertise, split between research and prac¬tice. Originally trained as a Landscape Ecologist I have studied in Germany, Iceland, New Zealand and the UK. My recent research sheds light on knowledge uptake for decision-making, stakeholder communication networks and effective factors for successful long-term networking. During my studies I have worked with multiple stakeholder groups including law enforce¬ment agents, national media. Working as a researcher and science communication expert has taught me how to connect biodiversity researchers with national, European and international policies and policy makers. I have co-au¬thored a Science paper about the failures of science-policy dialogue in relation to EU agricultural policy. I have more than 11,000 followers on Twitter and am a trained mediator since 2016.',
  resume: '', // if no resume, the button will not show up
};
// vision DATA
export const visionData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Impact Training',
    info: 'Impact Training for researchers',
    info2: '',
    url: '',
    // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    // if no repo, the button will not show up
  },
];
// Reference DATA
export const referenceData = {
  img1: 'markreed.png',
  img2: 'katharinafunk.png',
  paragraphOne:
    'I have more than 15 years of interdisciplinary expertise, split between research and practice. Originally trained as a Landscape Ecologist I have studied in Germany, Iceland, New Zealand and the UK. My recent research sheds light on knowledge uptake for decision-making, stakeholder communication networks and effective factors for successful long-term networking. During my studies I have worked with multiple stakeholder groups including law enforce¬ment agents, national media. Working as a researcher and science communication expert has taught me how to connect biodiversity researchers with national, European and international policies and policy makers. I have co-authored a Science paper about the failures of science-policy dialogue in relation to EU agricultural policy. I have more than 11,000 followers on Twitter and am a trained mediator since 2016.',
  resume: '', // if no resume, the button will not show up
};
// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Impact Training',
    info: 'Impact Training for researchers',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'rosmarie.katrin@impactdialog.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/RosmarieKatrin',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/dr-rosmarie-katrin-neumann-397a2286/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
